import React, { useMemo } from 'react';
import { FieldSelectEditor } from './FieldSelectEditor';
import { PanelPlugin } from '@grafana/data';
import { AlertManagerPicker } from 'app/features/alerting/unified/components/AlertManagerPicker';
import {
  getAllAlertManagerDataSources,
  NETMONITOR_RULES_SOURCE_NAME,
} from 'app/features/alerting/unified/utils/datasource';

import { SignGroupsPanel } from './SignGroupsPanel';
import { SignManagerPanelOptions } from './types';

export const plugin = new PanelPlugin<SignManagerPanelOptions>(SignGroupsPanel).setPanelOptions((builder) => {
  return builder
    .addCustomEditor({
      id: 'signId',
      path: 'signId',
      name: 'Signature ID Field',
      defaultValue: 'id',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'signName',
      path: 'signName',
      name: 'Signature Name Field',
      defaultValue: 'state',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'signState',
      path: 'signState',
      name: 'Signature State Field',
      description: 'The Signature state. If the signature if enable, this field must be true',
      defaultValue: 'state',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'signDate',
      path: 'signDate',
      name: 'Signature Date Field',
      description: 'The date from signature sign is enabled or disabled',
      defaultValue: 'date',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'signUser',
      path: 'signUser',
      name: 'Last user who modified the sign',
      description: 'The user who modified the sign for the last time',
      defaultValue: 'date',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'alarmName',
      path: 'alarmName',
      name: 'Alarm Name Field',
      description: 'The sign Name',
      defaultValue: 'name',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'alarmSummary',
      path: 'alarmSummary',
      name: 'Alarm Summary Field',
      description: 'The sign summary',
      defaultValue: 'summary',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'alarmType',
      path: 'alarmType',
      name: 'Alarm Type Field',
      description: 'Sign type. Colud be: Alarma, Alerta, TRAP, Resolucion, Reporte.',
      defaultValue: 'type',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'alarmSeverity',
      path: 'alarmSeverity',
      name: 'Alarm Severity  Field',
      description: 'Sign Severity. Could be the same severity options used in TRAPs',
      defaultValue: 'severity',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'alarmScope',
      path: 'alarmScope',
      name: 'Alarm Scope Field',
      description: 'Sign Scope. Could be Asset, Site, etc in wich the sign will be proceesed',
      defaultValue: 'category',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })	
    .addCustomEditor({
      id: 'openTicket',
      path: 'openTicket',
      name: 'Open Ticket Field',
      description: 'The Alarm must Open a Ticket?. If you want to open a ticket in external platform, this field must be true',
      defaultValue: 'ticket',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addTextInput({
      path: 'api',
      name: 'API URL',
      category: ['General Settings'],
      defaultValue: 'http://192.168.254.60:3001/rpc/update_information_data',
    })
    .addBooleanSwitch({
      path: 'editorCanAdmin',
      name: 'Editors can admin?',
      description: 'Enable editor to add, modify and delete alert channels',
      category: ['Options'],
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'showTitle',
      name: 'show customizable title',
      description: 'Enable title inside plugin',
      category: ['Options'],
      defaultValue: true,
    })
    .addTextInput({
      name: 'Title',
      path: 'pluginTitle',
      defaultValue: 'Notificaciones - Reglas configuradas',
      category: ['Options'],
      showIf: (options) => options.showTitle === true,
    })
    .addTextInput({
      name: 'Success Massage',
      path: 'successMessage',
      defaultValue: 'Regla modificada exitosamente',
      category: ['Options'],
      showIf: (options) => options.showTitle === true,
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});
