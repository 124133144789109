const graphitePlugin = async () =>
  await import(/* webpackChunkName: "graphitePlugin" */ 'app/plugins/datasource/graphite/module');
const cloudwatchPlugin = async () =>
  await import(/* webpackChunkName: "cloudwatchPlugin" */ 'app/plugins/datasource/cloudwatch/module');
const dashboardDSPlugin = async () =>
  await import(/* webpackChunkName "dashboardDSPlugin" */ 'app/plugins/datasource/dashboard/module');
const elasticsearchPlugin = async () =>
  await import(/* webpackChunkName: "elasticsearchPlugin" */ 'app/plugins/datasource/elasticsearch/module');
const opentsdbPlugin = async () =>
  await import(/* webpackChunkName: "opentsdbPlugin" */ 'app/plugins/datasource/opentsdb/module');
const netmonitorPlugin = async () =>
  await import(/* webpackChunkName: "netmonitorPlugin" */ 'app/plugins/datasource/netmonitor/module');
const influxdbPlugin = async () =>
  await import(/* webpackChunkName: "influxdbPlugin" */ 'app/plugins/datasource/influxdb/module');
const lokiPlugin = async () => 
  await import(/* webpackChunkName: "lokiPlugin" */ 'app/plugins/datasource/loki/module');
const jaegerPlugin = async () =>
  await import(/* webpackChunkName: "jaegerPlugin" */ 'app/plugins/datasource/jaeger/module');
const mixedPlugin = async () =>
  await import(/* webpackChunkName: "mixedPlugin" */ 'app/plugins/datasource/mixed/module');
const mysqlPlugin = async () =>
  await import(/* webpackChunkName: "mysqlPlugin" */ 'app/plugins/datasource/mysql/module');
const postgresPlugin = async () =>
  await import(/* webpackChunkName: "postgresPlugin" */ 'app/plugins/datasource/postgres/module');
const prometheusPlugin = async () =>
  await import(/* webpackChunkName: "prometheusPlugin" */ 'app/plugins/datasource/prometheus/module');
const mssqlPlugin = async () =>
  await import(/* webpackChunkName: "mssqlPlugin" */ 'app/plugins/datasource/mssql/module');
const testDataDSPlugin = async () =>
  await import(/* webpackChunkName: "testDataDSPlugin" */ 'app/plugins/datasource/testdata/module');
const cloudMonitoringPlugin = async () =>
  await import(/* webpackChunkName: "cloudMonitoringPlugin" */ 'app/plugins/datasource/cloud-monitoring/module');
const azureMonitorPlugin = async () =>
  await import(/* webpackChunkName: "azureMonitorPlugin" */ 'app/plugins/datasource/netmonitor-azure-monitor-datasource/module');
const alertmanagerPlugin = async () =>
  await import(/* webpackChunkName: "alertmanagerPlugin" */ 'app/plugins/datasource/alertmanager/module');
const netapiPlugin = async () => 
  await import(/* webpackChunkName: "netapiPlugin" */ 'app/plugins/datasource/net-api/module');
const netcameraPlugin = async () => 
  await import(/* webpackChunkName: "netcameraPlugin" */ 'app/plugins/datasource/netCamera/module');
const urlchekerPlugin = async () => 
  await import(/* webpackChunkName: "urlchekerPlugin" */ 'app/plugins/datasource/urlcheker/module');
const netsnmpPlugin = async () => 
  await import(/* webpackChunkName: "netsnmpPlugin" */ 'app/plugins/datasource/net-snmp/module');
const netsflowPlugin = async () => 
  await import(/* webpackChunkName: "netsflowPlugin" */ 'app/plugins/datasource/net-sflow/module');
const apidatasourcePlugin = async () => 
  await import(/* webpackChunkName: "apidatasourcePlugin" */ 'app/plugins/datasource/api_datasource/module');
  
import { config } from '@grafana/runtime';
import * as alarmManagerPanel from 'app/plugins/panel/alarmManager/module';
import * as alertGroupsPanel from 'app/plugins/panel/alertGroups/module';
import * as alertListPanel from 'app/plugins/panel/alertlist/module';
import * as contactManagerPanel from 'app/plugins/panel/contactManager/module';
import * as annoListPanel from 'app/plugins/panel/annolist/module';
import * as barChartPanel from 'app/plugins/panel/barchart/module';
import * as datatablePanel from 'app/plugins/panel/dataTable/module';
import * as buttonPanel from 'app/plugins/panel/buttons/module';
import * as customSignManagerPanel from 'app/plugins/panel/customSignManager/module';
import * as gaugeChartPanel from 'app/plugins/panel/gaugechart/module';
import * as genericFormPanel from 'app/plugins/panel/genericForm/module';
import * as indicatorPanel from 'app/plugins/panel/indicator/module';
import * as infoPanel from 'app/plugins/panel/infotable/module';
import * as imagePanel from 'app/plugins/panel/imagePanel/module';
import * as ipcheckPanel from 'app/plugins/panel/ipcheck/module';
import * as lineChartPanel from 'app/plugins/panel/linechart/module';
import * as linkInspectorPanel from 'app/plugins/panel/linkInspector/module';
import * as livePanel from 'app/plugins/panel/live/module';
import * as logsPanel from 'app/plugins/panel/logs/module';
import * as navbarPanel from 'app/plugins/panel/navbar/module';
import * as pieChartPanel from 'app/plugins/panel/piechart/module';
import * as profileManagerPanel from 'app/plugins/panel/profileManager/module';
import * as radarChartPanel from 'app/plugins/panel/radarchart/module';
import * as reportManagerPanel from 'app/plugins/panel/reportManager/module';
import * as reportInfoPanel from 'app/plugins/panel/reportPanel/module';
import * as sshPanel from 'app/plugins/panel/sshPanel/module';
import * as textPanel from 'app/plugins/panel/text/module';
import * as signManagerPanel from 'app/plugins/panel/signManager/module';
import * as sflowDecoderPanel from 'app/plugins/panel/sflowDecoder/module';
import * as tabPanel from 'app/plugins/panel/tabs/module';
import * as timelinePanel from 'app/plugins/panel/timeline/module';
import * as timeseriesPanel from 'app/plugins/panel/timeseries/module';
import * as topologymapPanel from 'app/plugins/panel/topologyMap/module';
import * as trapManagerPanel from 'app/plugins/panel/trapManager/module';
import * as treeChartPanel from 'app/plugins/panel/treechart/module';
import * as usersManagerPanel from 'app/plugins/panel/usersManager/module';
import * as videoPanel from 'app/plugins/panel/videoPanel/module';
import * as photoPanel from 'app/plugins/panel/photoPanel/module';

// Async loaded panels
const geomapPanel = async () => 
  await import(/* webpackChunkName: "geomapPanel" */ 'app/plugins/panel/geomap/module');

const builtInPlugins: any = {
  'app/plugins/datasource/graphite/module': graphitePlugin,
  'app/plugins/datasource/cloudwatch/module': cloudwatchPlugin,
  'app/plugins/datasource/dashboard/module': dashboardDSPlugin,
  'app/plugins/datasource/elasticsearch/module': elasticsearchPlugin,
  'app/plugins/datasource/opentsdb/module': opentsdbPlugin,
  'app/plugins/datasource/netmonitor/module': netmonitorPlugin,
  'app/plugins/datasource/influxdb/module': influxdbPlugin,
  'app/plugins/datasource/loki/module': lokiPlugin,
  'app/plugins/datasource/jaeger/module': jaegerPlugin,
  'app/plugins/datasource/mixed/module': mixedPlugin,
  'app/plugins/datasource/mysql/module': mysqlPlugin,
  'app/plugins/datasource/postgres/module': postgresPlugin,
  'app/plugins/datasource/mssql/module': mssqlPlugin,
  'app/plugins/datasource/prometheus/module': prometheusPlugin,
  'app/plugins/datasource/testdata/module': testDataDSPlugin,
  'app/plugins/datasource/cloud-monitoring/module': cloudMonitoringPlugin,
  'app/plugins/datasource/netmonitor-azure-monitor-datasource/module': azureMonitorPlugin,
  'app/plugins/datasource/alertmanager/module': alertmanagerPlugin,
  'app/plugins/datasource/netCamera/module': netcameraPlugin,
  'app/plugins/datasource/urlcheker/module': urlchekerPlugin,
  'app/plugins/datasource/net-api/module': netapiPlugin,
  'app/plugins/datasource/net-snmp/module': netsnmpPlugin,
  'app/plugins/datasource/net-sflow/module': netsflowPlugin,
  'app/plugins/datasource/api_datasource/module': apidatasourcePlugin,

  'app/plugins/panel/alarmManager/module': alarmManagerPanel,
  'app/plugins/panel/alertGroups/module': alertGroupsPanel,
  'app/plugins/panel/alertlist/module': alertListPanel,
  'app/plugins/panel/annolist/module': annoListPanel,
  'app/plugins/panel/contactManager/module': contactManagerPanel,
  'app/plugins/panel/barchart/module': barChartPanel,
  'app/plugins/panel/buttons/module': buttonPanel,
  'app/plugins/panel/customSignManager/module': customSignManagerPanel,
  'app/plugins/panel/dataTable/module': datatablePanel,
  'app/plugins/panel/gaugechart/module': gaugeChartPanel,
  'app/plugins/panel/geomap/module': geomapPanel,
  'app/plugins/panel/genericForm/module': genericFormPanel,
  'app/plugins/panel/imagePanel/module': imagePanel,
  'app/plugins/panel/indicator/module': indicatorPanel,
  'app/plugins/panel/infotable/module': infoPanel,
  'app/plugins/panel/ipcheck/module': ipcheckPanel,
  'app/plugins/panel/linechart/module': lineChartPanel,
  'app/plugins/panel/linkInspector/module': linkInspectorPanel,
  'app/plugins/panel/live/module': livePanel,
  'app/plugins/panel/logs/module': logsPanel,
  'app/plugins/panel/navbar/module': navbarPanel,
  'app/plugins/panel/piechart/module': pieChartPanel,
  'app/plugins/panel/profileManager/module': profileManagerPanel,
  'app/plugins/panel/radarchart/module': radarChartPanel,
  'app/plugins/panel/reportManager/module': reportManagerPanel,
  'app/plugins/panel/reportPanel/module': reportInfoPanel,
  'app/plugins/panel/signManager/module': signManagerPanel,
  'app/plugins/panel/sflowDecoder/module': sflowDecoderPanel,
  'app/plugins/panel/sshPanel/module': sshPanel,
  'app/plugins/panel/tabs/module': tabPanel,
  'app/plugins/panel/text/module': textPanel,
  'app/plugins/panel/timeline/module': timelinePanel,
  'app/plugins/panel/timeseries/module': timeseriesPanel,
  'app/plugins/panel/topologyMap/module': topologymapPanel,
  'app/plugins/panel/trapManager/module': trapManagerPanel,
  'app/plugins/panel/treechart/module': treeChartPanel,
  'app/plugins/panel/usersManager/module': usersManagerPanel,
  'app/plugins/panel/videoPanel/module': videoPanel,
  'app/plugins/panel/photoPanel/module': photoPanel,
};

export default builtInPlugins;
