import { css } from '@emotion/css';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { NetMonitorTheme2, AppEvents } from '@grafana/data';
import { Alert, LoadingPlaceholder, useStyles2 } from '@grafana/ui';
import { contextSrv } from 'app/core/services/context_srv';
import { FormSmSign } from './SmSigns';
import { SignGroup } from './SignGroup';

export const SignGroupsPanel: React.FC<Props> = ({ options, replaceVariables, id, width, height, data }) => {
  const styles = useStyles2(getStyles);
  const userName = contextSrv.user.name;
  const isAdmin = options.editorCanAdmin && contextSrv.isEditor ? true : contextSrv.isNetMonitorAdmin;
  const pluginTitle = replaceVariables(options.pluginTitle);
  const showTitle = options.showTitle;
  const okMessage = replaceVariables(options.successMessage);
  const noAlertMessage = replaceVariables(options.noAlertMessage) || 'No existen firmas';
  const error4 = replaceVariables(options.error4);
  const pluginApi = replaceVariables(options.api);

  const memoizedOptions = useMemo(() => ({ data: data }), [data]);

  const newSignsConfigured: FormSmSign[] = [];
  memoizedOptions.data.series.forEach(series => {
	const rulesVals: GraphSeriesValue[] = series.fields[0].values.toArray();
	for (let i = 0; i < rulesVals.length; i++) {
	  let ruleDate = {
		id: i,
		signId: series.fields.find(field => field.name === options.signId)?.values.get(i),
		signName: series.fields.find(field => field.name === options.signName)?.values.get(i),
		signState: series.fields.find(field => field.name === options.signState)?.values.get(i),
		signDate: series.fields.find(field => field.name === options.signDate)?.values.get(i),
		signUser: series.fields.find(field => field.name === options.signUser)?.values.get(i),
		alarmName: series.fields.find(field => field.name === options.alarmName)?.values.get(i),
		alarmSummary: series.fields.find(field => field.name === options.alarmSummary)?.values.get(i),
		alarmType: series.fields.find(field => field.name === options.alarmType)?.values.get(i),
		alarmSeverity: series.fields.find(field => field.name === options.alarmSeverity)?.values.get(i),
		alarmScope: series.fields.find(field => field.name === options.alarmScope)?.values.get(i),
		openTicket: series.fields.find(field => field.name === options.openTicket)?.values.get(i),
	  };
	  newSignsConfigured.push(ruleDate);
	}
  });
  const [signsConfigured, setSignsConfigured] = useState<FormSmSign[]>(newSignsConfigured);
  const noAlertsMessage = newSignsConfigured.length === 0 ? noAlertMessage : undefined;

  const handleSave = (data: Partial<FormSmSign>) => {
    const d = Date.now();
	var signToUpdate = '{"dest":"net_cfg_panel_notification_values_data", "params":"';
	const sign = data.find(rule => rule.idx !== undefined);
	if (sign) {
	  signToUpdate = signToUpdate +
		'1;' + sign.idx + '||' +
		'2;' + sign.state + '||' +
		'3;' + sign.name + '||' +
		'4;' + sign.summary + '||' +
		'5;' + sign.severity + '||' +
		'6;' + sign.openTicket + '||' +
		'7;' + userName + '||"}';
      axios.defaults.baseURL = pluginApi;
      axios.defaults.headers.post['Content-Type'] = 'application/json';
      axios.post(pluginApi, signToUpdate).then(
        response => {
		  if (response.statusText === 'OK') {
            SystemJS.load('app/core/app_events').then((appEvents: any) => {
              appEvents.emit(AppEvents.alertSuccess, ['Firma actualizada correctamente']);
			  setSignsConfigured(prevState => {
				const newState = [...prevState];
				newState[sign.id] = {
				  ...newState[sign.id],
					signState: sign.state,
					signUser: userName,
					signDate: new Date(d).toLocaleString(),
					alarmName: sign.name,
					alarmSummary: sign.summary,
					alarmSeverity: sign.severity,
					openTicket: sign.openTicket,
				};
				return newState;
			  });
            });
          } else {
            SystemJS.load('app/core/app_events').then((appEvents: any) => {
              appEvents.emit(AppEvents.alertSuccess, [response.statusText]);
            });
          }
        },
        error => {
          SystemJS.load('app/core/app_events').then((appEvents: any) => {
            appEvents.emit(AppEvents.alertError, ['Error al actualizar firma' + error.response.status]);
		  });
        }
      );
	}
  };
  if (width < 250 || height < 150) {
    return (
	  <div className="alertListErrorContainer" title={error4}>
	    <Icon name={'cloud-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.state === 'Error' || noAlertsMessage) {
    return (
	  <div className="alertListErrorContainer" title={noAlertsMessage}>
	    <Icon name={'sync-slash'} size="xxl" />
	  </div>
	);
  }

  return (
    <div className={styles.section} key={String(id)}>
      <SignGroup
	    id={String(id)}
	    showTitle={showTitle}
	    pluginTitle={pluginTitle}
	    isAdmin={isAdmin}
	    onChange={handleSave}
	    width={width}
		height={height}
	    signs={signsConfigured}
	  />
    </div>
  );
};

const getStyles = (theme: NetMonitorTheme2) => ({
  section: css`
    margin: 4px;
  `,
});
