import React, { useMemo } from 'react';
import { FieldSelectEditor } from './FieldSelectEditor';
import { PanelPlugin } from '@grafana/data';
import { AlertManagerPicker } from 'app/features/alerting/unified/components/AlertManagerPicker';
import {
  getAllAlertManagerDataSources,
  NETMONITOR_RULES_SOURCE_NAME,
} from 'app/features/alerting/unified/utils/datasource';

import { CustomSignGroupsPanel } from './CustomSignGroupsPanel';
import { CustomSignManagerPanelOptions } from './types';

export const plugin = new PanelPlugin<CustomSignManagerPanelOptions>(CustomSignGroupsPanel).setPanelOptions((builder) => {
  return builder
    .addCustomEditor({
      id: 'signId',
      path: 'signId',
      name: 'Signature ID Field',
      defaultValue: 'id',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'signName',
      path: 'signName',
      name: 'Signature Name Field',
      defaultValue: 'name',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'signAsset',
      path: 'signAsset',
      name: 'Asset ID Field',
      defaultValue: 'asset',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'signOid',
      path: 'signOid',
      name: 'Signature OID Field',
      defaultValue: 'state',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'signOidValueType',
      path: 'signOidValueType',
      name: 'OID value type Field',
      defaultValue: 'valueType',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'signThresholdBelow',
      path: 'signThresholdBelow',
      name: 'OID Threshold Up Field',
      description: 'Fire the alart when value is above or below threshold',
	  defaultValue: 'thresholdBelow',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'signDualThreshold',
      path: 'signDualThreshold',
      name: 'OID dual Threshold',
      defaultValue: 'thresholdDual',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'signOidThresholdUp',
      path: 'signOidThresholdUp',
      name: 'OID value Threshold Up Field',
      defaultValue: 'thresholdUp',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'signOidThresholdDown',
      path: 'signOidThresholdDown',
      name: 'OID value Threshold Down Field',
      defaultValue: 'thresholdDown',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'signType',
      path: 'signType',
      name: 'Signature Type Field',
      description: 'The Signature type. Pre-defined sign types or custom/generic type',
      defaultValue: 'state',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'signState',
      path: 'signState',
      name: 'Signature State Field',
      description: 'The Signature state. If the signature if enable, this field must be true',
      defaultValue: 'state',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'signDate',
      path: 'signDate',
      name: 'Signature Date Field',
      description: 'The date from signature sign is enabled or disabled',
      defaultValue: 'date',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'signUser',
      path: 'signUser',
      name: 'Last user who modified the sign',
      description: 'The user who modified the sign for the last time',
      defaultValue: 'date',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'alertName',
      path: 'alertName',
      name: 'Alert Name Field',
      description: 'The alert Name used in notificactions',
      defaultValue: 'alertName',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'alertSummary',
      path: 'alertSummary',
      name: 'Alert Summary Field',
      description: 'The alert summary used in notifications',
      defaultValue: 'summary',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'resolSummary',
      path: 'resolSummary',
      name: 'Alarm Resolution Field',
      description: 'Resolution text used in notifications.',
      defaultValue: 'type',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'alertSeverity',
      path: 'alertSeverity',
      name: 'Alert Severity  Field',
      description: 'Alert Severity. Could be the same severity options used in Alarms or TRAPs',
      defaultValue: 'severity',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'alertScope',
      path: 'alertScope',
      name: 'Alert Scope Field',
      description: 'Alert Scope. Could be Asset, Site, etc in wich the sign will be proceesed',
      defaultValue: 'category',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })	
    .addCustomEditor({
      id: 'openTicket',
      path: 'openTicket',
      name: 'Open Ticket Field',
      description: 'The Alarm must Open a Ticket?. If you want to open a ticket in external platform, this field must be true',
      defaultValue: 'ticket',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addTextInput({
      path: 'api',
      name: 'API URL',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'assetList',
      name: 'Asset List',
      category: ['General Settings'],
      description: 'A comma separeted list respecting the following format: assetID||assetDescription||assetType',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'assetTypes',
      name: 'Asset List Types',
      category: ['General Settings'],
	  description: 'A comma separeted list respecting the following format: type||typeDescription',
      defaultValue: '',
    })
    .addTextInput({
      path: 'signTypes',
      name: 'Sign Types List',
      category: ['General Settings'],
	  description: 'A comma separeted Sign type list respecting the following format: label||description||value',
      defaultValue: '',
    })	
    .addTextInput({
      path: 'assetVariable',
      name: 'Asset Variable',
      category: ['General Settings'],
      description: 'Asset variable to modify when press "Test OID"',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'oidVariable',
      name: 'OID Variable',
      category: ['General Settings'],
      description: 'OID variable to modify when press "Test OID"',
	  defaultValue: '',
    })
    .addBooleanSwitch({
      path: 'editorCanAdmin',
      name: 'Editors can admin?',
      description: 'Enable editor to add, modify and delete alert channels',
      category: ['Options'],
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'showTitle',
      name: 'show customizable title',
      description: 'Enable title inside plugin',
      category: ['Options'],
      defaultValue: true,
    })
    .addTextInput({
      name: 'Title',
      path: 'pluginTitle',
      defaultValue: 'Firmas customizadas - Reglas configuradas',
      category: ['Options'],
      showIf: (options) => options.showTitle === true,
    })
    .addTextInput({
      name: 'Success Massage',
      path: 'successMessage',
      defaultValue: 'Firma modificada exitosamente',
      category: ['Options'],
      showIf: (options) => options.showTitle === true,
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});
