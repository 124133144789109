import { SelectableValue } from '@grafana/data';

export interface SignManagerPanelOptions {
  api: string;
  assetList: string;
  assetTypes: string;
  signTypes: string;
  assetVariable: string;
  oidVariable: string;
  pluginTitle: string;
  signId: string;
  signAsset: string;
  signOid: string;
  signOidValueType: string;
  signDualThreshold: string;
  signThresholdBelow: string;
  signOidThresholdUp: string;
  signOidThresholdDown: string;
  signType: string;
  signName: string;
  signState: string;
  signDate: string;
  signUser: string;
  alertName: string;
  alertSummary: string;
  resolSummary: string;
  alertSeverity: string;
  alertScope: string;
  openTicket: string;
  successMessage: string;
  alertmanager: string;
  editorCanAdmin: boolean;
  showTitle: boolean;
  error4: string;
}

export const signTypeOptions: SelectableValue[] = [
  { label: 'Nivel de Temperatura', description: 'Temperatura excede umbral definido', value: 'temp' },
  { label: 'AA Encendido', description: 'Equipo de Aire Acondicionado en operación', value: 'aa' },
  { label: 'Fan Encendido', description: 'Sistema de ventilación forzada en operación', value: 'fan' },
  { label: 'Humedad Ambiente', description: 'Nivel de humedad ambiente excede umbral definido', value: 'wet' },
  { label: 'Presencia de Humo', description: 'Presencia de humo detectada', value: 'hume' },
  { label: 'Suministro Eléctrico', description: 'Servicio eléctrico interrumpido', value: 'ele_supply' },
  { label: 'Tensión de Entrada', description: 'Tensión de entrada excede umbral definido', value: 'in_voltage' },
  { label: 'Corriente de Entrada', description: 'Corriente de entrada excede umbral definido', value: 'in_current' },
  { label: 'ITM Principal', description: 'Proteccion termomagnetica principal activa', value: 'itm_main' },
  { label: 'DPS Principal', description: 'Proteccion contra sobre tensión principal activa', value: 'dps' },
  { label: 'ITM Carga', description: 'Proteccion termomagnetica de un circuito activa', value: 'itm_load' },
  { label: 'Tensión de Salida', description: 'Tensión de salida excede umbral definido', value: 'out_voltage' },
  { label: 'Corriente de Salida', description: 'Corriente  de salida excede umbral definido', value: 'out_current' },
  { label: 'Consumo', description: 'Consumo excede umbral definido', value: 'power' },
  { label: 'Nivel de Baterías', description: 'Nivel de carga de baterias excede umbral definido', value: 'battery_level' },
  { label: 'ITM Batería', description: 'Proteccion termomagnetica de baterías activa', value: 'itm_battery' },
  { label: 'Fuente AC', description: 'Fallo en fuente de alimentación AC', value: 'ac_ps' },
  { label: 'Fuente DC', description: 'Fallo en fuente de alimentación DC', value: 'dc_ps' },
  { label: 'Módulo DC/DC', description: 'Fallo en modulo de conversión DC/DC', value: 'dcdc_module' },
  { label: 'Módulo AC/DC', description: 'Fallo en modulo de conversión AC/DC', value: 'acdc_module' },
  { label: 'Generador Encendido', description: 'Generados electrico en operación', value: 'ge' },
  { label: 'Nivel de Combustible', description: 'Nivel de combustible del generador excede umbral definido', value: 'fuel' },
  { label: 'Puerta Abierta', description: 'Puerta abierta', value: 'door' },
  { label: 'Custom', description: 'Otro tipo de evento no clasificado', value: 'custom' },
];


export const valueTypeOptions: SelectableValue[] = [
  { label: 'Numerico', description: 'Valor numerico positivo o negativo', value: 'numeric' },
  { label: 'Logico', description: 'Un balor binario (true / false)', value: 'boolean' },
  { label: 'Texto', description: 'Un cadena de texto generica', value: 'string' },
];